















import { Component, Mixins } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import ConsultantFormBasicInfo from "@/components/consultant-form/ConsultantFormBasicInfo.vue";
import User, { UserRole } from "@/entity/User";
import BaseForm from "@/components/form/BaseForm";
import UserService from "@/services/UserService";

@Component({
  components: {
    FormCard,
    ConsultantFormBasicInfo,
  },
})
export default class ConsultantFormCard extends Mixins<BaseForm<User>>(
  BaseForm
) {
  data: User = {
    username: "",
    email: "",
    phone: "",
    displayName: "",
    enabled: true,
    role: UserRole.USER,
    enableEmailNotifications: false,
  };

  async submitForm(): Promise<void> {
    if (this.isEditing) {
      await UserService.update(this.entityId, this.data);
    } else {
      await UserService.create(this.data);
    }
  }

  async loadForm(): Promise<void> {
    this.data = await UserService.findOne(this.entityId);
  }

  created() {
    if (this.isEditing) {
      this.handleFormLoad(this.loadForm);
    }
  }
}
